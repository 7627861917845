import {Link} from "gatsby";
import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

const LinkCard = ({name, url, image="https://picsum.photos/600/400/?random"}) => (
  <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
    <article className="overflow-hidden rounded-lg shadow-lg">
      <Link to={url}>
        {image && typeof image === 'object' &&
        <GatsbyImage image={image} alt="Placeholder" className="block w-full h-auto" />
        }
        {image && typeof image === 'string' &&
          <img alt="Placeholder" className="block h-auto w-full" src={image} />
        }
      </Link>
      <header className="flex items-center justify-between leading-tight p-2 md:p-4">
        <h1 className="text-lg">
          <Link to={url} className={"text-black hover:no-underline"}>{name}</Link>
        </h1>
      </header>
    </article>
  </div>
)
export default LinkCard
